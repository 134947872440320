<template>
  <div
    class="relative"
    :class="{ hidden: !toggleFromParent && filterIcon }"
    data-cy="date-picker"
    @click="toggleVisible(true)"
  >
    <lf-input
      v-if="showTriggerButton && disabled"
      name="datepicker-disabled"
      disabled
      :placeholder="datePlaceholder"
      class="datepicker-disabled h-10 -mb-0"
    />
    <litepie-datepicker
      v-else
      ref="datePicker"
      v-slot="{ value, placeholder, clear }"
      v-model="dateValue"
      v-on-click-outside="() => toggleVisible(false)"
      :formatter="formatter"
      :placeholder="datePlaceholder"
      :shortcuts="windowWidth >= 1024 && shortcuts"
      :as-single="asSingle"
      :use-range="useRange"
    >
      <template v-if="showTriggerButton">
        <lf-button
          class="bg-white shadow-none border hover:border-gray-300 text-gray-600 flex items-start h-10 max-w-4xl w-full datepicker-enabled"
          :class="[
            {
              'pr-10': !!value
            }
          ]"
          :centered="false"
        >
          <span class="font-normal">
            {{ value || placeholder }}
          </span>
        </lf-button>
        <icon-base
          v-if="value"
          class="absolute right-4 top-3-4 cursor-pointer"
          :icon="IconClear"
          height="14"
          width="14"
          @click.stop.prevent="clear"
        />
      </template>
      <div v-else class="hidden">
        <!-- Hidden because if nothing is slotted the Litepie Datepicker component renders it's default button -->
      </div>
    </litepie-datepicker>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from "@vueuse/core";
import { ref, toRef, watch, onMounted, nextTick } from "vue";
import type { PropType } from "vue";
import { useI18n } from "vue-i18n";
// eslint-disable-next-line
// @ts-ignore
import LitepieDatepicker from "litepie-datepicker";
import isEqual from "lodash/isEqual";
import IconClear from "@/components/icons/IconClear.vue";
import { computed } from "vue";

const emit = defineEmits<{
  (e: "update:modelValue", newData: string[]): void;
  (e: "toggleVisible", isOpen: boolean): void;
}>();

const props = defineProps({
  formatter: {
    type: Object,
    default: null
  },
  datePlaceholder: {
    type: String,
    default: "Select Date"
  },
  modelValue: {
    type: Array as PropType<string[] | [string, string]>,
    required: true
  },
  filterIcon: {
    type: Boolean,
    default: false
  },
  toggleFromParent: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  asSingle: {
    type: Boolean,
    default: false
  },
  useRange: {
    type: Boolean,
    default: false
  },
  showOnMount: {
    type: Boolean,
    default: false
  },
  showTriggerButton: {
    type: Boolean,
    default: true
  },
  hideSecondMonth: {
    type: Boolean,
    default: false
  }
});
const dateValue = ref<string[]>(props.modelValue);
const datePicker = ref<InstanceType<typeof LitepieDatepicker> | null>(null);
const { t } = useI18n();
const { width: windowWidth } = useWindowSize();
const isVisible = ref(false);

const toggleVisible = (val: boolean) => {
  if (isVisible.value && val) {
    return;
  }

  isVisible.value = val;

  emit("toggleVisible", isVisible.value);

  const block: HTMLDivElement | null = document.querySelector(
    "#litepie .litepie-datepicker > div"
  );

  if (!block) {
    return;
  }

  if (!val) {
    block.style.maxHeight = "unset";
    block.style.overflowY = "unset";
    return;
  }

  setTimeout(() => {
    const rect = block.getBoundingClientRect();
    const availableSpaceBelow = window.innerHeight - rect.top;
    const bottomPadding = 10;
    if (availableSpaceBelow < rect.height) {
      block.style.maxHeight = availableSpaceBelow - bottomPadding + "px";
      block.style.overflowY = "scroll";
    } else {
      block.style.maxHeight = "unset";
      block.style.overflowY = "unset";
    }
  }, 0);
};

const shortcuts = () => [
  {
    label: t("DATE_PICKER_SHORTCUTS.TODAY"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const todayStart = new Date(date);
      const todayEnd = new Date(date.setDate(date.getDate() + 1));
      todayEnd.setMilliseconds(-1);
      return [todayStart, todayEnd];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.YESTERDAY"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const yesterdayEnd = new Date(date);
      yesterdayEnd.setMilliseconds(-1);
      const yesterdayStart = new Date(date.setDate(date.getDate() - 1));
      return [yesterdayStart, yesterdayEnd];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.THIS_WEEK"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const firstDay = new Date(date.setDate(date.getDate() - date.getDay()));
      const lastDay = new Date(
        date.setDate(date.getDate() - date.getDay() + 7)
      );
      lastDay.setMilliseconds(-1);
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.LAST_WEEK"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const firstDay = new Date(
        date.setDate(date.getDate() - date.getDay() - 7)
      );
      const lastDay = new Date(
        date.setDate(date.getDate() - date.getDay() + 7)
      );
      lastDay.setMilliseconds(-1);
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.THIS_MONTH"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      lastDay.setMilliseconds(-1);
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.LAST_MONTH"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay.setMilliseconds(-1);
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.LAST_30_DAYS"),
    atClick: () => {
      const lastDay = new Date();
      lastDay.setHours(0, 0, 0, 0);
      const firstDay = new Date(new Date().setDate(lastDay.getDate() - 29));
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.THIS_QUARTER"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const quarter = Math.floor(date.getMonth() / 3);
      const firstDay = new Date(date.getFullYear(), quarter * 3, 1);
      const lastDay = new Date(date.getFullYear(), quarter * 3 + 3, 1);
      lastDay.setMilliseconds(-1);
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.LAST_6_MONTHS"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const lastDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      lastDay.setMilliseconds(-1);
      const firstDay = new Date(date.setMonth(date.getMonth() - 6));
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.THIS_CALENDAR_YEAR"),
    atClick: () => {
      const date = new Date();
      date.setMonth(0, 1);
      date.setHours(0, 0, 0, 0);
      const firstDay = new Date(date);
      const lastDay = new Date(date.getFullYear() + 1, 0, 0);
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.LAST_CALENDAR_YEAR"),
    atClick: () => {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 1, 0, 1);
      date.setHours(0, 0, 0, 0);
      const firstDay = new Date(date);
      const lastDay = new Date(date.getFullYear() + 1, 0, 0);
      return [firstDay, lastDay];
    }
  },
  {
    label: t("DATE_PICKER_SHORTCUTS.LAST_365_DAYS"),
    atClick: () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const lastDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDay()
      );
      lastDay.setMilliseconds(-1);
      const firstDay = new Date(date.setDate(date.getDate() - 364));
      return [firstDay, lastDay];
    }
  }
];

const disabled = toRef(props, "disabled");

const secondMonthDisplay = computed(() => {
  return props.hideSecondMonth ? "none" : "";
});

watch(
  () => props.modelValue,
  (newVal: string[]) => {
    if (!newVal) {
      return;
    }
    if (!isEqual(dateValue.value, newVal)) {
      dateValue.value = [...newVal];
      if (!newVal.length) {
        datePicker.value?.clearPicker();
      }
    }
  },
  { immediate: true }
);

watch(dateValue, (newVal) => {
  nextTick(() => {
    emit("update:modelValue", newVal);
    setTimeout(() => {
      emit("toggleVisible", false);
    }, 100);
  });
});

watch(
  () => props.toggleFromParent,
  (value) => {
    if (datePicker.value) {
      datePicker.value.isShow = value;
    }
  }
);

watch(
  () => props.asSingle,
  () => {
    datePicker.value?.clearPicker();
  }
);

onMounted(() => {
  if (props.showOnMount && datePicker.value) {
    datePicker.value.isShow = true;
  }
});
</script>

<style scoped>
:deep() {
  #litepie .litepie-datepicker,
  #litepie .litepie-datepicker .border-black {
    @apply border-gray-200;
  }

  #litepie .litepie-datepicker > div > div > div:last-child,
  #litepie .litepie-datepicker > div > div > div:first-child {
    display: v-bind(secondMonthDisplay);
  }

  #litepie .litepie-datepicker > div > div > div:last-child {
    display: v-bind(secondMonthDisplay);
  }
}
</style>
